<template>
  <div class="select_dropdown" :class="small ? 'select_dropdown--small' : ''" v-click-outside="vcoConfig">
      <input type="text" ref="reference" class="input_component select_dropdown__input"
        :class="readonly && 'select_dropdown__input--readonly', isSelected && 'select_dropdown__input--selected'"
        name="reason" :readonly="readonly == true" :placeholder="placeholder" :value="value" @input="input" @change="change"
        @focus="value.length > 0 ? isOpened = true : ''">
      <div v-if="isSelected" class="select_dropdown__list__item select_dropdown__list__item--active">
        <div class="text-ellipsis color-black font-weight-bold">{{isSelected?.display_name ?? `${isSelected?.name} ${isSelected?.last_name}`}}</div>
        <div class="text-ellipsis">{{ isSelected?.phone }}</div>
        <div class="text-ellipsis font-weight-bold"> {{ isSelected?.email }}</div>
        <div class="text-ellipsis"> {{ isSelected?.address }} {{ isSelected?.suburb }} {{ isSelected.state?.code }}
          {{ isSelected.postcode }}</div>
      </div>
      <div class="tw-relative tw-inline">
        <div v-if="!isSelected" class="input__icon">
          <svg-icon name="regular/search" />
        </div>
        <div v-else @click="remove" class="input__icon">
          <svg-icon name="solid/times" />
        </div>
        <div v-if="!editLinkText && isSelected && edit" class="input__icon_second" @click="$emit('editItem')"><svg-icon name="solid/pencil" />
        </div>
      </div>
      <div  v-if="editLinkText && isSelected && edit" class="tw-flex tw-justify-end tw-mt-2">
        <button type="button" @click="$emit('editItem')" class="btn btn_link btn_green tw-m-0 tw-p-0">Edit Customer</button>
      </div>

    <!-- Panel Start -->
    <Teleport to="body">
    <div ref="floating" :style="floatingStyles" v-show="isOpened" class="select_dropdown__list" :class="isSelected && 'select_dropdown__list--selected'">
      <div v-if="loading && list.length < 1" class="select_dropdown__list__preloader">
        <PreloaderSmall />
      </div>
      <div v-for="item in list" @click="changeSelect(item)" class="select_dropdown__list__item"
        :class="item === value ? 'select_dropdown__list__item--selected' : ''">
        <div class="text-ellipsis color-black font-weight-bold">{{item?.display_name ?? `${item?.name} ${item?.last_name}`}}</div>
        <div class="text-ellipsis">{{ item?.phone }}</div>
        <div class="text-ellipsis font-weight-bold"> {{ item?.email }}</div>
        <div class="text-ellipsis"> {{ item?.address }} {{ item?.suburb }} {{ item.state?.code }} {{ item.postcode }}</div>
      </div>
      <div v-if="!loading && addNew" class="select_dropdown__list__item" @click="$emit('addNewItem')">
        <div class="text-ellipsis color-black font-weight-bold"><svg-icon name="solid/plus" /> Create new customer</div>
      </div>
    </div>
  </Teleport>
  </div>
</template>

<script>
import { reactive, ref, computed, watch } from 'vue'
import vClickOutside from 'click-outside-vue3'
import PreloaderSmall from "@/components/PreloaderSmall";
import {useFloating, autoUpdate, size} from '@floating-ui/vue';

export default {
  name: "SelectDropdown",
  components: {
    PreloaderSmall,
    
},
  props: ['list', 'placeholder', 'small', 'value', 'readonly', 'loading', 'addNew', 'edit', 'selection', 'editLinkText'],
  emits: ['select', 'change', 'input'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  setup(props, { emit }) {

    const reference = ref(null);
    const floating = ref(null);
    const {floatingStyles} = useFloating(reference, floating, {
      whileElementsMounted: autoUpdate,
      placement: "bottom-start",
      middleware: [
        size({
          apply({elements, availableHeight, reacts}) {
            Object.assign(elements.floating.style, {
              width: `${elements.reference.clientWidth}px`,
              maxHeight: `${availableHeight-10}px`,
            })
          },
        })
      ],
    });

    watch(() => props.selection, (val) => {
      isSelected.value = val
    })

    let isOpened = ref(false)
    let isSelected = ref(props.selection)
    let changeSelect = (item) => {
      emit('select', item);
      isSelected.value = item
      closeDropdown()
    }
    let input = (event) => {
      if (event.target.value.length > 0) {
        isOpened.value = true
      } else {
        closeDropdown()
      }
      emit('input', event.target.value);
    }
    let change = (event) => {
      emit('change', event.target.value);
    }
    let closeDropdown = () => {
      isOpened.value = false
    }

    const vcoConfig = {
        handler: closeDropdown,
        // middleware: (event) => {
        //   console.log('Middleware')
        //   return event.target.className !== 'modal'
        // },
        events: ['dblclick', 'click'],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
        // Note: The default value is false. Sets the capture option for EventTarget addEventListener method.
        //       Could be useful if some event's handler calls stopPropagation method preventing event bubbling.
        capture: false,
    }


    let remove = (e) => {
      closeDropdown()
      isSelected.value = false
      emit('input', '');
      emit('select', null);
    }
    return {
      reference,
      floating,
      floatingStyles,
      changeSelect,
      isOpened,
      isSelected,
      closeDropdown,
      input,
      change,
      remove,
      vcoConfig,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/utils/vars";

.select_dropdown {
  position: relative;
  z-index: 3;

  &__input {
    width: 100%;
    padding: 16px 48px 16px 20px;
    height: 50px;
    font-size: 15px;
    border: 1px solid rgba(28, 40, 51, 0.3);
    border-radius: 2px;
    color: rgba(28, 40, 51, 0.8);
    transition: all .2s;
    cursor: pointer;

    &--readonly {
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.05), rgba(11, 53, 83, 0.05)), #FFFFFF;
    }
  }

  .input__icon {
    position: absolute;
    right: 20px;
    font-size: 16px;
    color: rgba(28, 40, 51, 0.6);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input__icon_second {
    position: absolute;
    right: 40px;
    font-size: 16px;
    color: var(--PrimaryButtonColour);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__list {
    top: 123px;
    position: fixed;
    width: 100%;
    max-height: 45vh;
    overflow: auto;
    background: #FFFFFF;
    border: 1px solid rgba(28, 40, 51, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(28, 40, 51, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 9999;

    &__preloader {
      width: 100%;
      height: 60px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      width: 100%;
      padding: 10px 20px;
      height: 40px;
      transition-duration: 0.2s;
      font-size: 13px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(11, 53, 83, 0.2);
      cursor: pointer;

      &>div {
        &:nth-child(1) {
          width: 190px;
          font-weight: bold;
          margin-right: 5px;
        }

        &:nth-child(2) {
          width: 110px;
          margin-right: 5px;
        }

        &:nth-child(3) {
          width: 225px;
          color: #59b692;
          margin-right: 5px;
        }
      }

      &:hover {
        background: rgba(11, 53, 83, 0.1)
      }

      &--selected {
        background: rgba(11, 53, 83, 0.1)
      }

      &--active {
        height: 48px;
        position: absolute;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        overflow: hidden;
        border-bottom: none;
        cursor: initial;

        &>div {
          width: auto !important;
          margin-right: 10px !important;
        }

        &:hover {
          background: #FFFFFF;
        }
      }
    }
  }

  &--small {
    .select_dropdown {
      position: relative;
      cursor: pointer;

      &__input {
        padding: 12px 32px 12px 10px;
        height: 40px;
      }

      &__icon {
        right: 16px;
        font-size: 14px;
        top: 10px;
        color: rgba(28, 40, 51, 0.8);
      }

      &__list {
        top: 40px;
        z-index: 5;

        &__item {
          padding: 10px;
          transition-duration: 0.2s;
          font-weight: normal;
          font-size: 15px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .select_dropdown {
    &__input {
      &--selected {
        height: 95px;
      }
    }

    &__list {
      top: 50px;
      z-index: 999;
      box-shadow: 0px 0px 10px rgba(28, 40, 51, 0.3);

      &--active {
        top: 95px;
      }

      &__item {
        height: auto;
        flex-direction: column;

        &>div {
          width: 100% !important;
        }

        &--active {
          height: 93px;
          padding-right: 32px;

          &>div {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}</style>
